<template lang="pug">
BasePane(:savedStyle="false")
  SmartHeadlineGeneration.mt-1.mb-5(v-if="isSmartHeadlineFeatureEnabled" dsAllowed)
  Accordion.mt-3(fix)
    template(#title)
      .font-weight-bold {{ $t('style') }}
    template(v-if="isUniversal")
      CustomThemeStyle(dsAllowed dropdownStyle)
      MoreOrLess(gapSize="4px")
        Shadow(
          :labelOverride="$t('textShadow')"
          v-model="textShadow"
          coloringProperty="desktop.textShadow.color"
          typeOfComponent="text"
          textShadow
        )
        RangeInput(
          :label="$t('textRotation')"
          :min="0"
          :max="360"
          :step="1"
          unit="°"
          v-model="textRotation"
        )
    template(v-else)
      Shadow(
        :labelOverride="$t('textShadow')"
        v-model="textShadow"
        coloringProperty="desktop.textShadow.color"
        typeOfComponent="text"
        textShadow
      )
      RangeInput(
        :label="$t('textRotation')"
        :min="0"
        :max="360"
        :step="1"
        unit="°"
        v-model="textRotation"
      )
  hr.mb-5
  template(v-if="mobilePreview")
    Accordion(fix)
      template(#title)
        .font-weight-bold {{ $t('textSettings') }}
      RangeInput(:label="$t('fontSize')" :min="0" :max="200" :step="1" unit="%" v-model="fontSize")
      AlignDropdown(v-model="alignment" layout="col")
  hr.mb-5(v-if="mobilePreview")
  Accordion(fix)
    template(#title)
      .font-weight-bold {{ $t('spacing') }}
    template(#device)
      DeviceSelector(:hasViewText="false")
    Margin
  hr.mt-5.mb-3
  template(#advancedContent)
    Padding
    Hide(:hideOnDesktop.sync="hideElementOnDesktop" :hideOnMobile.sync="hideElementOnMobile")
    BackgroundAndBorder(hideTitle typeOfComponent="text" fixOptions shadow :editMobile="false")
</template>
<script>
  import { mapState, mapGetters } from 'vuex';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import MoreOrLess from '@/components/Editor/MoreOrLess.vue';
  import Heading from '@/components/Editor/Heading.vue';
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import RangeInput from '@/components/Editor/RangeInput/RangeInput.vue';
  import Margin from '@/components/Editor/Controls/InputGroup/Margin.vue';
  import Padding from '@/components/Editor/Controls/InputGroup/Padding.vue';
  import Shadow from '@/components/Editor/Controls/Shadow.vue';
  import AlignDropdown from '@/components/Editor/Controls/Alignments/AlignDropdown.vue';
  import Hide from '@/components/Editor/Controls/Hide.vue';
  import BackgroundAndBorder from '@/components/Editor/Blocks/BackgroundAndBorder.vue';
  import { SMART_HEADLINE_GENERATOR } from '@/utils/features';
  import itemMixin from '@/editor/mixins/item';
  import DeviceSelector from '../../components/DeviceSelector.vue';
  import BasePane from './BasePane.vue';

  export default {
    components: {
      Accordion,
      MoreOrLess,
      Heading,
      Dropdown,
      RangeInput,
      Margin,
      Padding,
      Shadow,
      AlignDropdown,
      BasePane,
      Hide,
      BackgroundAndBorder,
      DeviceSelector,
      SmartHeadlineGeneration: () =>
        import('@/editor/components/sidebar/components/SmartHeadlineGeneration.vue'),
      CustomThemeStyle: () => import('@/editor/components/sidebar/components/CustomThemeStyle.vue'),
    },
    mixins: [itemMixin],
    data() {
      return {
        canEditMobile: true,
      };
    },

    computed: {
      ...mapState(['mobilePreview']),
      ...mapGetters(['isUniversal', 'hasAccountFeature', 'isTemplateEditorMode']),
      textShadow: {
        get() {
          return this.getValueOf('textShadow.type');
        },
        set(value) {
          this.setValueOf('textShadow.type', value);
        },
      },
      textRotation: {
        get() {
          return Number(this.getValueOf('desktop.textRotation'));
        },
        set(v) {
          this.setValueOf('desktop.textRotation', v);
        },
      },
      fontSize: {
        get() {
          return Number(this.getValueOf('mobile.fontMultiplier'));
        },
        set(v) {
          this.setValueOf('mobile.fontMultiplier', v);
        },
      },
      alignment: {
        get() {
          return this.getValueOf('mobile.textAlign');
        },
        set(value) {
          this.setValueOf('mobile.textAlign', value);
        },
      },
      shadow: {
        get() {
          return this.getValueOf('shadow.type');
        },
        set(value) {
          this.setValueOf('shadow.type', value);
        },
      },
      hideElementOnDesktop: {
        get() {
          return this.getValueOf('desktop.hidden');
        },
        set(v) {
          this.setValueOf('desktop.hidden', v);
        },
      },
      hideElementOnMobile: {
        get() {
          return this.smartGetValueOf('mobile.hidden');
        },
        set(v) {
          this.smartSetValueOf('mobile.hidden', v);
        },
      },
      isSmartHeadlineFeatureEnabled() {
        return this.hasAccountFeature(SMART_HEADLINE_GENERATOR) && !this.isTemplateEditorMode;
      },
    },
  };
</script>
